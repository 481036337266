import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Typography, LinearProgress } from '@mui/material';
import { AuthService } from 'Services/AuthService';
import Login from 'Auth/Login';
import withUserProfile from 'Auth/withUserProfile';
import EagerLoader from 'State/EagerLoader';
import CustomLoginCallback from 'Auth/CustomLoginCallback';
import PolicyView from 'Policy/PolicyView';
import ProjectView from 'NewHomeProjects/ProjectView';
import StringUtil from 'Util/StringUtil';

const DealsListPage = lazy(() => import('Admin/DealsListPage'));
const UsersListPage = lazy(() => import('Admin/UsersListPage'));
const PinDbUpdate = lazy(() => import('Admin/PinDatabase/PinDbUpdate'));
const PinView = lazy(() => import('Admin/PinDatabase/PinView'));
const PinEdit = lazy(() => import('Admin/PinDatabase/PinEdit'));
const PinAdd = lazy(() => import('Admin/PinDatabase/PinAdd'));
const UserConfirmEmail = lazy(() => import('Auth/UserConfirmEmail'));
const SignUp = lazy(() => import('Auth/SignUp'));
const SignUpSuccess = lazy(() => import('Auth/SignUpSuccess'));
const MigrateLawPROUserSuccess = lazy(() => import('Auth/MigrateLawPROUserSuccess'));
const WelcomeLawPROFriend = lazy(() => import('Auth/WelcomeLawPROFriend'));
const Register = lazy(() => import('User/Register'));
const RegisterSuccess = lazy(() => import('User/RegisterSuccess'));
const ResetRequired = lazy(() => import('Auth/ResetRequired'));
const Expired = lazy(() => import('Auth/Expired'));
const SecurityQuestionReset = lazy(() => import('Auth/SecurityQuestionReset'));
const ForgotPassword = lazy(() => import('Auth/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('Auth/ForgotPasswordSuccess'));
const ExpiredSuccess = lazy(() => import('Auth/ExpiredSuccess'));
const ForgotPasswordQuery = lazy(() => import('Auth/ForgotPasswordQuery'));
const ForgotUsername = lazy(() => import('Auth/ForgotUsername'));
const ForgotUsernameQuery = lazy(() => import('Auth/ForgotUsernameQuery'));
const UserProfileAssociations = lazy(() => import('User/Profile/Associations'));
const MonthlyBilling = lazy(() => import('User//MonthlyBilling'));
const PrivateLendersList = lazy(() => import('Admin/PrivateLenders/List'));
const PrivateLenderEdit = lazy(() => import('Admin/PrivateLenders/Edit'));
const PrivateLenderAdd = lazy(() => import('Admin/PrivateLenders/Add'));
const AccountSettings = lazy(() => import('User/AccountSettings/AccountSettings'))
const MessagingList = lazy(() => import('Admin/Messaging/List'));
const AddMessage = lazy(() => import('Admin/Messaging/Add'));
const EditMessage = lazy(() => import('Admin/Messaging/Edit'));
const SupplementaryList = lazy(() => import('Admin/Supplementary/SupplementaryList'));
const SupplementaryEdit = lazy(() => import('Admin/Supplementary/SupplementaryEdit'));
const ReportsList = lazy(() => import('Admin/Reports/List'));
const Announcements = lazy(() => import('Admin/Announcement/List'));
const CreateAnnouncement = lazy(() => import('Admin/Announcement/Create'));
const EditAnnouncement = lazy(() => import('Admin/Announcement/Edit'));
const ProjectsList = lazy(() => import('Admin/Projects/List'));
const TagsList = lazy(() => import('Admin/Tags/List'));
const Tag = lazy(() => import('Admin/Tags/Tag'));

function LoadingRoute(props) {
    return (
        <div>
            <LinearProgress />
            <Typography align="center">Loading...</Typography>
        </div>
    );
}

const searchParams = new URLSearchParams(window.location.search);

const at = searchParams.get("access_token");

if(!StringUtil.isNullOrEmpty(at)) {
    AuthService.injectAccessToken(at);
}

const ProtectedRoute = AuthService.isAccessTokenInjected ? withUserProfile(Route) : withUserProfile(SecureRoute);
// const ProtectedRoute = SecureRoute;
// const ProtectedRoute = withUserProfile(Route); // IGOR IE 11 FOR HENRY

function Routes() {
    const hist = useHistory();

    if(!StringUtil.isNullOrEmpty(at)) {
        AuthService.removeInjectedAccessTokenAndRedirect(hist);
    }

    function onAuthRequiredHandler(oktaAuth) {        
        AuthService.onPreLogin();
        
        hist.push('/login');

        return Promise.resolve();
    }

    function restoreOriginalUri(oktaAuth, originalUri) {
        const newUri = toRelativeUrl(originalUri, window.location.origin);
        
        hist.replace(newUri);
        
        return Promise.resolve();
    }

    return (
        <Security oktaAuth={AuthService.authClient} onAuthRequired={onAuthRequiredHandler} restoreOriginalUri={restoreOriginalUri} >
            <EagerLoader />
            <Suspense fallback={<LoadingRoute />}>
                <Switch>
                    <ProtectedRoute path="/" exact component={DealsListPage} />
                    <ProtectedRoute path="/admin/deals" exact component={DealsListPage} />
                    <ProtectedRoute path="/admin/users" exact component={UsersListPage} />
                    <ProtectedRoute path="/admin/pin-database" exact component={PinDbUpdate} />
                    <ProtectedRoute path="/admin/pin-database/view/:pinId" exact component={PinView} />
                    <ProtectedRoute path="/admin/pin-database/edit/:pinId" exact component={PinEdit} />
                    <ProtectedRoute path="/admin/pin-database/add" exact component={PinAdd} />
                    <ProtectedRoute path="/admin/supplementary-list" exact component={SupplementaryList} />
                    <ProtectedRoute path="/admin/supplementary/edit/:recordId" exact component={SupplementaryEdit} />
                    <ProtectedRoute path="/admin/private-lenders/list" exact component={PrivateLendersList} />
                    <ProtectedRoute path="/admin/private-lenders/edit/:id" exact component={PrivateLenderEdit} />
                    <ProtectedRoute path="/admin/private-lenders/add" exact component={PrivateLenderAdd} />
                    
                    <ProtectedRoute path="/user/profile/account-settings" component={AccountSettings} />

                    <ProtectedRoute path="/admin/messaging/list" exact component={MessagingList} />
                    <ProtectedRoute path="/admin/messaging/add" exact component={AddMessage} />
                    <ProtectedRoute path="/admin/messaging/edit/:id" exact component={EditMessage} />
                    <ProtectedRoute path="/policy" component={PolicyView} />
                    <ProtectedRoute path="/project" component={ProjectView} />
                    <ProtectedRoute path="/admin/reports/list" exact component={ReportsList} />
                    <ProtectedRoute path="/admin/announcement/list" exact component={Announcements} />
                    <ProtectedRoute path="/admin/announcement/create" exact component={CreateAnnouncement} />
                    <ProtectedRoute path="/admin/announcement/edit/:id" exact component={EditAnnouncement} />
                    <ProtectedRoute path="/admin/projects/list" exact component={ProjectsList} />
                    <ProtectedRoute path="/admin/tags/list" exact component={TagsList} />
                    <ProtectedRoute path="/admin/tags/tag" exact component={Tag} />

                    <ProtectedRoute path="/user/register" component={Register} />
                    <ProtectedRoute path="/user/register-success" component={RegisterSuccess} />
                    <ProtectedRoute path="/user/profile/associations" component={UserProfileAssociations} />
                    <ProtectedRoute path="/monthly-billing" component={MonthlyBilling} />
                    <Route path="/login" component={Login} />
                    <Route path="/confirm-email" component={UserConfirmEmail} />
                    <Route path='/implicit/callback' component={CustomLoginCallback} />
                    <Route path="/signup" component={SignUp} />
                    <Route path="/signup-success" component={SignUpSuccess} />
                    <Route path="/welcome-lawpro-friend" component={WelcomeLawPROFriend} />
                    <Route path="/migrate-lawpro-success" component={MigrateLawPROUserSuccess} />
                    <Route exact path="/reset-required/:fName/:lName/:sessionToken" component={ResetRequired} />
                    <Route exact path="/expired/:uName/:isShadow" component={Expired} />
                    <Route exact path="/security-question-reset/:uName" component={SecurityQuestionReset} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/forgot-password-query/:token" component={ForgotPasswordQuery} />
                    <Route exact path="/forgot-password-success" component={ForgotPasswordSuccess} />
                    <Route exact path="/expired-password-success" component={ExpiredSuccess} />
                    <Route exact path="/forgot-username" component={ForgotUsername} />
                    <Route exact path="/forgot-username-query/:token" component={ForgotUsernameQuery} />
                </Switch>
            </Suspense>
        </Security>
    );
}

function RouterSetup(props) {
    return (
        <Router>
            <Routes />
        </Router>
    );
}

export default RouterSetup;
