import React from 'react';
import { SectionHeader } from 'Components/Questionary';
import MortgageInfo from './MortgageInfo';
import makeStyles from '@mui/styles/makeStyles';
import { SectionHelper } from '../../Util/SectionHelper';
import { useDispatch, useSelector } from 'react-redux'
import * as PolicyActions from 'Policy/State/PolicyActions';
import { CollectionTypeEnum } from 'Model/PolicyOrder';

import { Fragment } from 'react';
import Conditional from 'Policy/Conditional';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(3)
    }
}));

function MortgagesSection(props) {
    const classes = useStyles();

    const { questionary, questionParams, itemsList, property, propertyConditionalProps } = props;

    const dispatch = useDispatch();

    const collectionName = CollectionTypeEnum.Mortgages;

    const canAdd = itemsList.length < 6;

    const closingDate = useSelector((x) => x.order.closingDate)

    function add() {
        const newItem = SectionHelper.addNewSection(collectionName, questionParams, { MortgagePropertyId: property.Id, MortgageTPInsured:'yes', MortgageAdvanceDate: closingDate });

        dispatch({ type: PolicyActions.add_mortgage.type, payload: newItem });
    }

    function remove(item) {
        SectionHelper.removeSection({ collectionName, item, questionParams });

        dispatch({ type: PolicyActions.remove_mortgage.type, payload: item });
    }

    return (
        //ShowSection() && 
        <Conditional upon="ShouldShowMortgageSection" when={(x) => x == true} {...propertyConditionalProps}>
            <Fragment>
                <SectionHeader section="mortgages" title="Mortgages" questionary={questionary} subSection />
                <div className={classes.root}>
                    {itemsList.map((item, index) => {
                        return (
                            <MortgageInfo
                                key={item.Id}
                                idx={index}
                                item={item}
                                collectionName={collectionName}
                                canAdd={canAdd}
                                add={add}
                                remove={remove}
                                questionParams={questionParams}
                                propertyConditionalProps={propertyConditionalProps}
                                property={property}
                            />
                        );
                    })}
                </div>
            </Fragment>
        </Conditional>
    );
}

export default MortgagesSection;
