import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PolicyApiClient } from 'Policy/PolicyApiClient'

const initialState = {
    active_tags: [],
    status: 'idle',
    error: null,
    active_tags_count: 0,
    active_tags_pages_count: 0,
}

// Thunk functions
export const fetchActiveTags = createAsyncThunk(
    'active_tags/fetch',
    async () => {
        const response = await PolicyApiClient.getActivePolicyTags()
        return response.data
    })


const ActivePolicyTagsSlice = createSlice({
    name: 'active_tags',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchActiveTags.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchActiveTags.fulfilled, (state, action) => {
                if (state.active_tags === undefined ||
                    state.active_tags === null ||
                    state.active_tags.length === 0) {
                        state.active_tags = []
                }
                if (action.payload.PolicyTags === undefined ||
                    action.payload.PolicyTags === null ||
                    action.payload.PolicyTags.length === 0) {
                        action.payload.PolicyTags = []
                }
                if (action.payload.PolicyTags.length > 0) {
                    state.active_tags = action.payload.PolicyTags
                    state.active_tags_count = action.payload.PolicyTags.length
                }
                state.status = 'succeeded'
            })
            .addCase(fetchActiveTags.rejected, (state, action) => {
                state.status = 'failed'
                state.error = 'Failed to fetch active tags'
                state.active_tags = []
            })
    }
})

export default ActivePolicyTagsSlice.reducer

// Selectors
export const selectActiveTags = (state) => state.active_tags
export const selectActiveTagsStatus = (state) => state.status
export const selectActiveTagsError = (state) => state.error
export const selectActiveTagCount = (state) => state.active_tags_count