import { Box, Grid, Icon, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CurrencyTextField from '../../Components/CurrencyTextField';
import clsx from 'clsx';
import AutoCompleteFreeFormQuestion from 'Components/AutoCompleteFreeFormQuestion';
import CheckBoxOptionGroup from 'Components/CheckBoxOptionGroup';
import DropDown from 'Components/DropDown';
import DropZone from 'Components/FileUpload/DropZone';
import Label from 'Components/Label';
import MultipleOptionGroup from 'Components/MultipleOptionGroup';
import OptionGroup from 'Components/OptionGroup';
import PhoneMask from 'Components/PhoneMask';
import SingleCheckBox from 'Components/SingleCheckBox';
import withFieldStateBindings from 'Components/withFieldStateBindings';
import { withOnChangeDebounced } from 'Components/withOnChangeDebounced';
import withTwoWayFieldStateBindings from 'Components/withTwoWayFieldStateBindings';
import { ProvincesDDList } from 'Model/PolicyOrder';
import React from 'react';
import { StringUtil } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import AdverseMattersFromTitleSearch from './CustomQuestions/AdverseMattersFromTitleSearch';
import BorrowerLawyerName from './CustomQuestions/BorrowerLawyerName';
import NewHomeProgramWarningForPartners from './CustomQuestions/NewHomeProgramWarningForPartners';
import PostalCode from './CustomQuestions/PostalCode';
import PropertiesList from './CustomQuestions/PropertiesList';
import PropertyPINs from './CustomQuestions/PropertyPINs';
import TermsAndConditions from './CustomQuestions/TermsAndConditions';
import DateControl from './DateControl';
import QuestionTitle from './QuestionTitle';

const useStyles = makeStyles(theme => ({
    questionBody: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1.5)
    },
    shortTextField: {
        width: '50ch',
        paddingRight: theme.spacing(2)
    },
    longTextField: {
        width: '100%',
        paddingRight: theme.spacing(2)
    },
    spacingFix: {
        marginTop: 8
    },
    dropZone: {
        marginRight: theme.spacing(2)
    },
    iconRoot: {
        marginBottom: -25,
        left: -47,
        position: 'relative',
        float: 'left',
        overflow: 'visible',
        top: 60
    },
    imageIcon: {
        height: 28
    },
    justify: {
        marginLeft: theme.spacing(1.5),
        //marginRight: theme.spacing(2)
    },
    indent: {
        marginLeft: theme.spacing(0.5)
    }
}));

//Fix and use commented block after go-live

const LabelBound = withTwoWayFieldStateBindings(Label);
const TextFieldBound = withFieldStateBindings(withTwoWayFieldStateBindings(withOnChangeDebounced(TextField)));
const DropDownBound = withFieldStateBindings(withTwoWayFieldStateBindings(DropDown));
const AutoCompleteBound = withFieldStateBindings(withTwoWayFieldStateBindings(AutoCompleteFreeFormQuestion));
const DateControlBound = withFieldStateBindings(withTwoWayFieldStateBindings(DateControl));
const OptionGroupBound = withFieldStateBindings(withTwoWayFieldStateBindings(OptionGroup));
const CheckBoxOptionGroupBound = withFieldStateBindings(CheckBoxOptionGroup);
const MultipleOptionGroupBound = withFieldStateBindings(MultipleOptionGroup);
const CurrencyTextFieldBound = withFieldStateBindings(CurrencyTextField);
const SingleCheckBoxBound = withFieldStateBindings(SingleCheckBox);
const PropertyPINsBound = withFieldStateBindings(PropertyPINs);
const AdverseMattersFromTitleSearchBound = withFieldStateBindings(AdverseMattersFromTitleSearch);

function Question(props) {
    const classes = useStyles();

    const {
        qId,
        questionary,
        inputProps,
        subQuestion,
        hideLabel,
        topBuffer,
        multiline,
        noborder,
        optional,
        readOnly,
        getUploadUrls,
        isReviewRequired,
        short,
        indexer,
        inline,
        txtWidth,
        twoWayStateResolver,
        handleInputChange,
        onSearchEventHandler,
        allowFreeText,
        displayOnly,
        disabled,
        labelExplicit,
        questionTitle,
        leadingChar,
        minStringLength,
        ...other
    } = props;

    let { fieldStateResolver } = props;

    const q = QuestionaryHelper.getQuestion(questionary, qId);

    if (q == null) {
        return null;
    }

    if (!StringUtil.isNullEmptyOrWhiteSpace(questionTitle))
        q.Text = questionTitle;

    q.Optional = optional;

    let fieldName = qId;
    let readOnlyConfigFieldName = `ShouldDisable${qId}`;

    if (!StringUtil.isNullOrEmpty(indexer)) {
        fieldName = `${indexer}${fieldName}`;
        readOnlyConfigFieldName = `${indexer}${readOnlyConfigFieldName}`;
    }

    let rdOnly = readOnly;
    let disbld = disabled;


    //#region Check if data is sourced from partner and lock as read-only
    const isReadonly = inputProps(fieldName, readOnlyConfigFieldName).readOnly;

    if (isReadonly) {
        disbld = true;
        rdOnly = true;
    }

    let roProps = { disabled: disbld, readOnly: rdOnly };

    //#endregion Locking end

    let answerControl = null;

    if (displayOnly) {
        answerControl = (
            <LabelBound
                twoWayStateResolver={twoWayStateResolver}
                {...inputProps(fieldName, readOnlyConfigFieldName)}
                leadingChar={leadingChar} minStringLength={minStringLength} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "Container")) {
        // answerControl is nothing
    }
    else if (StringUtil.isEqual(q.Type, "MultiPart")) {
        answerControl = <Box mb={2} />;
    }
    else if (StringUtil.isEither(q.Type, "SingleSelection", "YesNo")) {
        answerControl = (
            <OptionGroupBound fieldName={fieldName} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} opts={q.Options} {...inputProps(fieldName, readOnlyConfigFieldName)} {...roProps} {...other} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "SingleSelectionCheckBox")) {
        answerControl = (
            <CheckBoxOptionGroupBound fieldName={fieldName} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} opts={q.Options} {...inputProps(fieldName, readOnlyConfigFieldName)} {...roProps} {...other} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "CheckBox")) {
        answerControl = (
            <SingleCheckBoxBound fieldName={fieldName} fieldStateResolver={fieldStateResolver} label={q.ContentText || labelExplicit} {...inputProps(fieldName, readOnlyConfigFieldName)} opts={q.Options} {...roProps} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "MultipleSelection")) {
        answerControl = (
            <MultipleOptionGroupBound opts={q.Options} fieldName={fieldName} fieldStateResolver={fieldStateResolver} {...inputProps(fieldName, readOnlyConfigFieldName)} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "FileUpload")) {
        const uploadUrls = getUploadUrls(q);

        answerControl = (
            <DropZone uploadUrls={uploadUrls} readOnly={rdOnly} containerClass={classes.dropZone} />
        );
    }
    else if (StringUtil.isEqual(q.Type, "AutoCompleteDropDownSelection")) {
        answerControl = <AutoCompleteBound q={q} opts={q.Options} fieldName={fieldName} subQuestion={subQuestion} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} {...inputProps(fieldName, readOnlyConfigFieldName)} onSearchEventHandler={onSearchEventHandler} allowFreeText={allowFreeText} {...roProps} {...other} />
    }
    else if (StringUtil.isEqual(q.Type, "DropDownSelection")) {
        answerControl = <DropDownBound q={q} opts={q.Options} fieldName={fieldName} subQuestion={subQuestion} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} {...inputProps(fieldName, readOnlyConfigFieldName)} {...roProps} />;
    }
    else if (StringUtil.isEqual(q.Type, "PropertiesList")) {
        answerControl = <PropertiesList q={q} fieldName={fieldName} subQuestion={subQuestion} optionGroupProps={inputProps} />
    }
    else if (StringUtil.isEqual(q.Type, "Province")) {
        answerControl = <DropDownBound q={q} opts={ProvincesDDList} fieldName={fieldName} subQuestion={subQuestion} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} {...inputProps(fieldName, readOnlyConfigFieldName)} {...roProps} {...other} />
    }
    else if (StringUtil.isEqual(q.Type, "Date")) {
        answerControl = <DateControlBound q={q} fieldName={fieldName} fieldStateResolver={fieldStateResolver} twoWayStateResolver={twoWayStateResolver} {...inputProps(fieldName, readOnlyConfigFieldName)} {...roProps} />
    }    
    else {
        const addl = {
            required: !optional,
            readOnly: rdOnly,
            autoComplete: 'off'
        };

        if (rdOnly) {
            addl.InputProps = { readOnly: true };
        }

        if (subQuestion) {
            addl.label = q.Text;
        }

        if (hideLabel)
            addl.label = '';

        if (multiline) {
            addl.multiline = true;

            if (!noborder)
                addl.variant = "outlined";
        }

        if (noborder) {
            addl.InputProps = {
                disableUnderline: true,
                readOnly: rdOnly
            };
        }

        if (StringUtil.isEqual(q.Type, "BorrowerLawyerName")) {
            answerControl = (
                <BorrowerLawyerName qId={qId} textFieldProps={inputProps} addl={addl} optionGroupProps={inputProps} indexer={indexer} other={other} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "TermsAndConditions")) {
            const p = { qId, fieldName, addl, inputProps };

            answerControl = (
                <TermsAndConditions {...p} {...other} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "ShowNewHomeProgramWarningForPartners")){
            answerControl = (
                <NewHomeProgramWarningForPartners qId={qId} indexer={indexer} fieldStateResolver={fieldStateResolver} {...inputProps(fieldName, readOnlyConfigFieldName)} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "Numeric")) {
            addl.inputProps = null;
            addl.decimalPlaces = 0;
            addl.currencySymbol = '';
            addl.digitGroupSeparator = '';
            addl.readOnly = false;
            addl.disabled = rdOnly;

            answerControl = (
                <CurrencyTextFieldBound variant="standard" fieldStateResolver={fieldStateResolver} {...addl} {...inputProps(fieldName, readOnlyConfigFieldName)} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "Currency")) {
            addl.inputProps = null;
            addl.readOnly = false;
            addl.disabled = rdOnly;
            answerControl = (
                <CurrencyTextFieldBound variant="standard" fieldStateResolver={fieldStateResolver} {...addl} {...inputProps(fieldName, readOnlyConfigFieldName)} />
            );
        }
        else if (StringUtil.isEqual(q.Type, "PropertyPIN")) {
            answerControl = (
                <PropertyPINsBound qId={qId}
                    indexer={indexer}
                    optionGroupProps={inputProps}
                    twoWayStateResolver={twoWayStateResolver}
                    {...addl}
                    other={other}
                />
            );
        }
        else if (StringUtil.isEqual(qId, "PropertyPostalCode")) {
            answerControl = <PostalCode qId={qId}
                indexer={indexer}
                textFieldProps={inputProps}
                twoWayStateResolver={twoWayStateResolver}
                {...addl}
                other={other} />
        }
        else if (StringUtil.isEqual(q.Type, "AdverseMattersFromTitleSearch")) {
            answerControl = (
                <AdverseMattersFromTitleSearchBound qId={qId} fieldStateResolver={fieldStateResolver} opts={q.Options} {...inputProps(fieldName, readOnlyConfigFieldName)} {...addl} />
            );
        }
        // plain text field...
        else {
            let cName = classes.longTextField;
            let txtStyle = {};

            if (txtWidth) {
                cName = null;
                txtStyle = { width: `${txtWidth}ch` };
            }
            else if (short) {
                cName = classes.shortTextField;
            }

            // not so plain ...
            if (StringUtil.isEqual(q.Type, "Phone")) {
                if (!addl.InputProps) {
                    addl.InputProps = {};
                }

                addl.InputProps.inputComponent = PhoneMask;
            }

            answerControl = (
                <TextFieldBound
                    className={cName}
                    style={txtStyle}
                    fieldStateResolver={fieldStateResolver}
                    twoWayStateResolver={twoWayStateResolver}
                    {...other}
                    {...addl}
                    {...inputProps(fieldName, readOnlyConfigFieldName)}
                    disabled={disbld} />
            );
        }
    }

    if (subQuestion || hideLabel || displayOnly) {
        let subQClassName = null;

        if (topBuffer) {
            subQClassName = classes.spacingFix;
        }

        if (hideLabel)
            subQClassName = classes.justify;

        if (displayOnly)
            subQClassName = classes.indent;

        return (
            <React.Fragment>
                {isReviewRequired && isReviewRequired(fieldName) &&
                    <Icon classes={{ root: classes.iconRoot }}>
                        <img className={classes.imageIcon} src="/img/under_review.svg" />
                    </Icon>
                }
                <div className={subQClassName}>
                    {answerControl}{props.children}
                </div>
            </React.Fragment>);
    }

    let gridClass = clsx(classes.questionBody, topBuffer ? classes.spacingFix : null);

    if (inline) {
        return (
            <div>
                <QuestionTitle q={q} isReviewRequired={isReviewRequired} indexer={indexer} inline={inline} />
                <div className={gridClass}>
                    {answerControl}{props.children}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <QuestionTitle q={q} isReviewRequired={isReviewRequired} indexer={indexer} />
            <Grid item xs className={gridClass}>
                {answerControl}{props.children}
            </Grid>
        </React.Fragment>
    );
}

export default Question;
