import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { AppSettings } from 'AppSettings';
import ErrorSnackbar from 'Components/ErrorSnackbar';
import NotAuthorized from 'Components/NotAuthorized';
import { SectionHeader } from 'Components/Questionary';
import { BackgroundCheckTypeEnum } from 'Model/BackgroundCheck';
import { PermittedOrderActionsEnum } from 'Model/PolicyOrder';
import * as UserPermissions from 'Model/UserPermissions';
import NotesFrame from 'Notes/NotesFrame2.3';
import { useDispatch, useSelector } from 'react-redux';
import * as UserActions from 'User/State/UserActions';
import { StringUtil, UriUtil, UserHelper } from 'Util/Helpers';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import { ValidationHelper } from 'Util/ValidationHelper';
import BackgroundCheck from './BackgroundCheck';
import AutoSaver from './FX/AutoSaver';
import ProjectPropertyReloader from './FX/ProjectPropertyReloader';
import QuestionaryReloader from './FX/QuestionaryReloader';
import { PolicyApiClient } from './PolicyApiClient';
import PolicyDecisionActions from './PolicyDecisionActions';
import PolicyViewActions from './PolicyViewActions';
import PolicyViewFrame from './PolicyViewFrame';
import PolicyViewHelper from './PolicyViewHelper';
import CertificateOfTitleSection from './Sections/CertificateOfTitleSection';
import EndorsementsView from './Sections/Endorsements/EndorsementsView';
import ExceptionsView from './Sections/Exceptions/ExceptionView';
import OrderSummaryView from './Sections/OrderSummary/OrderSummaryView';
import PolicyDocumentsSection from './Sections/PolicyDocumentsSection';
import PropertiesSection from './Sections/PropertiesSection';
import TransactionSection from './Sections/TransactionSection';
import EventLogs from './EventLogs';
import * as PolicyActions from './State/PolicyActions';
import * as PolicyThunks from './State/PolicyThunks';
import AutoCalculator from './FX/AutoCalculator';
import InsureOverView from './Sections/InsureOvers/InsureOverView';
import { FormFieldHelper } from 'Components/Form/FormFieldHelper';
import Alert from '@mui/material/Alert';
import {AdminApiClient} from "../Admin/AdminApiClient";
import AnnouncementsView from "../Components/RichTextEditor/AnnouncementsView";

const urljoin = require('url-join');

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  // ---------------
  loadingSection: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  form: {
    width: '90%',
    // width: BrowserHelper.isIE11 ? '660px' : '100%', // Fix IE 11 issue.
    margin: theme.spacing(2)
  }
}));

function PolicyView(props) {
  const classes = useStyles();
  const orderState = useSelector(x => x.order);
  const orderId = orderState.orderVolatileData.SecureId;

  const [initLoadData, setInitLoadData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: true,
      loadingErrorMessage: '',
      questionary: {},
      reviewMap: {},
      po: {},
      answersMap: null,
      canView: true
    }
  );

  const [inputs, setInputs] = useState({});
  const [changingStatus, setChangingStatus] = useState(false); // IGOR can we get rid and just use isBusy??
  const [actionMsg, setActionMsg] = useState(''); // IGOR -- let's get rid, this is now in redux
  const [actionMsgType, setActionMsgType] = useState(''); // IGOR -- let's get rid, this is now in redux
  // IGOR NEEED TO GET RID OF THESE, replace with Redux

  const [messages, setMessages] = useState([]);
  const { po, loading, loadingErrorMessage, questionary, reviewMap } = initLoadData;

  const selectUserInfo = useMemo(
    () => UserActions.createUserInfoSelector(),
    []
  );

  const userInfo = useSelector(x => selectUserInfo(x));
  const dispatch = useDispatch();
  const viewHelper = new PolicyViewHelper(po, dispatch);

  const selectChange = useMemo(
    () => FormFieldHelper.createFieldStateSelector(),
    []
  );

  const permittedActions = useSelector(x => selectChange(x.order.orderVolatileData, "PermittedActions"));
  //IGOR -> Change order status causes an issue as the permitted actions are resolved only onload
  //Resolving it from redux store will reload the entire parent
  const allowSave = StringUtil.contains(permittedActions, "Save");

  // FIRST LOAD
  // IGOR When using useState and invoking setState form aync location (such as from useEffect), keep in mind
  // React will NOT batch the updates, causing re-render for each setState call ..
  useEffect(() => {
    let isCancelled = false;
    getApplicationPageMessages();
    function onUnload() {
      isCancelled = true;
    }

    async function loadDeal() {
      let secureIdToLoad = viewHelper.getDealIdFromQueryString();

      if (StringUtil.isNullOrEmpty(secureIdToLoad)) {
        secureIdToLoad = 'new';

        if (!UserHelper.hasPermission(userInfo, UserPermissions.create_deal)) {
          if (!isCancelled) {
            setInitLoadData({ loading: false, canView: false });
          }
          return;
        }
      }

      const result = await PolicyApiClient.loadPolicy(secureIdToLoad);

      if (result.hasError) {
        if (!isCancelled) {
          setInitLoadData({ loading: false, loadingErrorMessage: result.errorMessage });
        }
        return onUnload;
      }

      let newReviewMap = {};

      if (result.hasValidationInfo) {
        newReviewMap = ValidationHelper.getValidationMap(result.data); // IGOR -- this is Kenneth
      }

      const newPo = result.data.PolicyOrder;
      if (!isCancelled) {
        const newQuestionary = newPo.Questionary;
        const answersMap = new Map();

        // inputs must be set before setLoading(false)...
        for (const q of newQuestionary.Questions) {
          QuestionaryHelper.setAnswerInitialValue(newPo, q, answersMap, isCancelled);

          //Set Readonly property to Answer Map
          let _q = { ...q };
          _q.Id = `ShouldDisable${_q.Id}`;
          QuestionaryHelper.setAnswerInitialValue(newPo, _q, answersMap, isCancelled);
        }

        delete newPo.Questionary;
        viewHelper.setNewOrderData(newPo);

        setInitLoadData({
          loading: false,
          loadingErrorMessage: '',
          questionary: newQuestionary,
          reviewMap: newReviewMap,
          po: newPo,
          answersMap
        });
      }
    }

    loadDeal();
    return onUnload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initLoadData.loading === false && initLoadData.canView === false) {
    return <NotAuthorized />
  }

  if (loading) {
    return (
      <div className={classes.loadingSection}>
        <LinearProgress />
        <Typography align="center">Loading policy...</Typography>
      </div>
    );
  }

  if (!StringUtil.isNullOrEmpty(loadingErrorMessage)) {
    return (
      <div style={{ marginTop: '0.5em' }}><ErrorSnackbar variant='error' message={loadingErrorMessage} centered={true} /></div>
    );
  }

  function getInitialAnswer(fieldName) {
    if (!initLoadData || !initLoadData.answersMap || !fieldName) {
      return null;
    }
    const a = initLoadData.answersMap.get(fieldName);
    return a;
  }


  function getReadOnlyAttribute(fieldName) {
    if (!initLoadData || !initLoadData.answersMap || !fieldName) {
      return null;
    }
    const a = initLoadData.answersMap.get(fieldName);
    return a;
  }

  const handleInputChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    dispatch({ type: PolicyActions.changeOrder.type, payload: { fieldName, fieldValue } });
    dispatch({ type: PolicyActions.clearValidationError.type, payload: { fieldName } });
  }

  const isReviewRequired = (fieldName) => {
    if (StringUtil.isNullOrEmpty(reviewMap) || StringUtil.isNullOrEmpty(fieldName))
      return null;

    const e = reviewMap[fieldName.toLowerCase()];
    if (StringUtil.isNullOrEmpty(e))
      return null;

    return StringUtil.isEqual(e.ErrorCode, 'REVIEW_REQUIRED');
  }

  function setSecureIdUrlParam(secureId) {
    if (StringUtil.isNullOrEmpty(secureId)) {
      return;
    }
    UriUtil.replaceUrlParam('id', secureId);
  }

  function save(event, submitOrder) {
    dispatch(PolicyThunks.saveOrder(po, submitOrder));
  }

  async function restore(event) {
    setActionMsgType('');
    setActionMsg('');
    setChangingStatus(true);
    const resp = await PolicyApiClient.restoreOrder(po.SecureId);
    setChangingStatus(false);
    if (resp.hasError) {
      setActionMsgType('error');
      setActionMsg(resp.errorMessage);
      if (!resp.hasValidationResult) {
        return;
      }
    }

    const respData = resp.data;
    const validationMap = ValidationHelper.getValidationMap(respData);

    if (!StringUtil.isNullOrEmpty(validationMap)) {
      return; // we hit a validation error
    }

    setActionMsgType(respData.Status);
    setActionMsg(respData.StatusDescription);
    const newPo = respData.PolicyOrder;
  }

  async function changeOrderStatus(event, orderStatus) {
    dispatch(PolicyThunks.changeOrderStatus(po, orderStatus));
  }

  async function executeCheck(type) {
    const policyId = po.SecureId;
    let result = null;
    switch (type) {
      case BackgroundCheckTypeEnum.PinCheckOnPolicies:
        result = await PolicyApiClient.retryPinCheckOnPolicies(policyId, po.Matter.Properties);
        break;
      case BackgroundCheckTypeEnum.LawyerCheck:
        result = await PolicyApiClient.retryLawyerCheck(policyId, "A012751R");
        break;
      case BackgroundCheckTypeEnum.NameChecksOnSupplementalDb:
        result = await PolicyApiClient.retryNameCheckOnSupplementalDb(policyId, "can wit");
        break;
      default:
        result = { hasError: true, errorMessage: `Unknown check type: ${type}` };
        break;
    }

    if (result.hasError) {
      setActionMsgType('error');
      setActionMsg(result.errorMessage);
      return;
    }

    if (result.data.HasValidationErrors) {
      setActionMsgType('error');
      setActionMsg(`${type} : Retry failed`);
      return;
    }
    return;
  }

  const isBusy = loading || changingStatus; // IGOR TODO - get rid of changingStatus

  function inputProps(name, readOnlyConfigFieldName) {
    const p = {
      name: name,
      onChange: handleInputChange,
      defaultValue: getInitialAnswer(name) || '',
      readOnly: getReadOnlyAttribute(`${readOnlyConfigFieldName}`) || false
    };
    return p;
  }

  function getUploadUrls(q) {
    const id = orderId ? orderId : po.SecureId;
    
    const urls = {
      purge: urljoin(AppSettings.urls.documents, `/policy/Purge?poId=${id}&questionId=${q.Id}`),
      archivedList: urljoin(AppSettings.urls.documents, `/policy/ListArchivedFiles?poId=${id}&questionId=${q.Id}`),
      list: urljoin(AppSettings.urls.documents, `/policy/ListUploadedFiles?poId=${id}&questionId=${q.Id}`),
      upload: urljoin(AppSettings.urls.documents, `/policy/GetUploadFileUrl?poId=${id}&questionId=${q.Id}`),
      view: urljoin(AppSettings.urls.documents, `/policy/ViewUploadedFile?poId=${id}&questionId=${q.Id}`),
      viewArchivedDoc: urljoin(AppSettings.urls.documents, `/policy/ViewArchivedFile?poId=${id}&questionId=${q.Id}`),
      delete: urljoin(AppSettings.urls.documents, `/policy/Delete?poId=${id}&questionId=${q.Id}`),
    };

    return urls;
  }

  const readOnly = !allowSave;

  const questionParams = {
    getUploadUrls,
    questionary,
    inputProps,
    readOnly,
    isReviewRequired,
    fieldStateResolver: (x) => x.order.formState,
    twoWayStateResolver: (x) => x.order.shadowChanges
  };

  const policyViewActionsParams = {
    isBusy,
    save,
    changingStatus,
    restore,
    po,
    actionMsgType,
    setActionMsgType,
    actionMsg,
    setActionMsg,
    changeOrderStatus,
    readOnly
  };

  const policyDecisionActionsParams = {
    po,
    changeOrderStatus
  };

  const notesParams = {
    notes: po.Notes,
    reviewValidationMap: reviewMap,
    policyId: po.SecureId
  };

  const backgroundCheckParams = {
    executeCheck: executeCheck,
    po
  };

  //#region Background Checks

  const backgroundChecks = <React.Fragment>
    <SectionHeader section="checks" title="Background Checks" />
    <BackgroundCheck type={BackgroundCheckTypeEnum.PinCheckOnPolicies} text="Pin check on issued policies" {...backgroundCheckParams} />
    <BackgroundCheck type={BackgroundCheckTypeEnum.PinCheckOnDirectory} text="Pin check on directory" {...backgroundCheckParams} />
    <BackgroundCheck type={BackgroundCheckTypeEnum.LawyerCheck} text="Lawyer eligibility" {...backgroundCheckParams} />
    <BackgroundCheck type={BackgroundCheckTypeEnum.SubmittingLawyerCheck} text="Submitting Lawyer eligibility" {...backgroundCheckParams} />
    <BackgroundCheck type={BackgroundCheckTypeEnum.NameChecksOnSupplementalDb} text="Name" {...backgroundCheckParams} />
  </React.Fragment>

  //#endregion Background Checks

  //#region Notes

  const notesFrame = StringUtil.contains(po.PermittedActions, PermittedOrderActionsEnum.TakeNotes) &&
    <Grid container spacing={2}>
      <SectionHeader title="Notes" />
      <NotesFrame {...notesParams} />
    </Grid>;

  //#endregion Notes

  function getApplicationPageMessages() {
    const req = {"IsValid": "true"};
    const result = AdminApiClient.getCurrentMessages(req)
        .then(result => {
          const allCurrentMessages = result.data.Messages;
          if (allCurrentMessages.length > 0) {
            const applicationMessages = allCurrentMessages.filter(msg => {
              return msg.ApplicationPage === true
            });
            if (applicationMessages.length > 0) {
              setMessages(applicationMessages);
            }
          }
        });
  };

  const messagesFrame =
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {messages.map(msg => {
            return (<Alert key={msg.Id} severity={msg.DisplayType.toLowerCase()}>{msg.Text}</Alert>)
          })}
          <br />
        </Grid>
      </Grid>;

  const showBgChecks = UserHelper.hasPermission(userInfo, UserPermissions.view_background_checks) && StringUtil.containsAny(po.PermittedActions, PermittedOrderActionsEnum.Approve, PermittedOrderActionsEnum.Decline);
  const sectionParams = { questionary, questionParams, inputs, setInputs, po, getInitialAnswer };

  return (
      <>
        <PolicyViewFrame po={po} userInfo={userInfo} permittedActions={permittedActions} {...policyViewActionsParams}>
          <br />
          <AnnouncementsView type={'application'} user={`${userInfo.FirstName}_${userInfo.LastName}`} />
          <div className={classes.toolbar} />
          {messagesFrame}
          <form className={classes.form} noValidate autoComplete="off">
            <PolicyDecisionActions {...policyDecisionActionsParams} />
            {notesFrame}
            <Grid container spacing={2}>
              {showBgChecks && backgroundChecks}
              <TransactionSection {...sectionParams} />
              <PropertiesSection {...sectionParams} />
              <ExceptionsView po={po} userInfo={userInfo} permittedActions={permittedActions} />
              <EndorsementsView po={po} userInfo={userInfo} permittedActions={permittedActions} />
              <InsureOverView po={po} userInfo={userInfo} permittedActions={permittedActions} />
              <EventLogs po={po} userInfo={userInfo} permittedActions={permittedActions} />
              <OrderSummaryView {...sectionParams} />
              <PolicyDocumentsSection po={po} {...sectionParams} />
              <CertificateOfTitleSection {...sectionParams} userInfo={userInfo} />
              <Grid item xs={12}>
                <PolicyViewActions {...policyViewActionsParams} />
              </Grid>
            </Grid>
          </form>
          <AutoSaver po={po} />
          <QuestionaryReloader getInitialAnswer={getInitialAnswer} />
          <AutoCalculator po={po} />
          <ProjectPropertyReloader />
        </PolicyViewFrame>
      </>
  );
}

export default PolicyView;
