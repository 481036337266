import React, { useState, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography, FormControl, FormHelperText } from '@mui/material';
import TagsFilterDL from './Filters/TagsFilterDL';
import { StringUtil, UserHelper } from 'Util/Helpers';
import { AdminApiClient } from './AdminApiClient';
import { ValidationHelper } from 'Util/ValidationHelper';
import { useSelector } from 'react-redux';
import * as UserPermissions from 'Model/UserPermissions';
import * as UserActions from 'User/State/UserActions';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    title: {
        fontWeight: 500
    },
    formControl: {
        width: '100%'
    }
}));

export default function PolicyTags(props) {
    const classes = useStyles();

    const {
        po,
        setActionMsgType,
        setActionMsg
    } = props;

    const secureId = po.SecureId;

    const [validationMsg, setValidationMsg] = useState('');
    const [working, setWorking] = useState(false);

    const selectUserInfo = useMemo(() => UserActions.createUserInfoSelector(), []);
    const userInfo =  useSelector(x => selectUserInfo(x));
    const canTagDeal = UserHelper.hasPermission(userInfo, UserPermissions.tag_deal);

    async function handleTagsChange(event) {
        const newTags = event.target.value;

        setWorking(true);
        setValidationMsg('');

        const resp = await AdminApiClient.changeTags(secureId, newTags);

        setWorking(false);

        if (resp.hasError) {
          setActionMsgType('error');
          setActionMsg(resp.errorMessage);
    
          if (!resp.hasValidationResult) {
            return;
          }
        }

        const respData = resp.data;
        const mirroredBack = respData.Tags;
        const validationMap = ValidationHelper.getValidationMap(respData);
    
        if (!StringUtil.isNullOrEmpty(validationMap)) {
          return; // we hit a validation error -- TODO, set validations msg
        }
    
        setActionMsgType(respData.Status);
        setActionMsg(respData.StatusDescription);
        return mirroredBack;
    }

    return (
        <Box p={0} m={0} mb={1} className={classes.root}>
            <Typography variant="subtitle1" className={classes.title}>
                Tags:
            </Typography>
            <FormControl
                className={classes.formControl}
                error={!StringUtil.isNullOrEmpty(validationMsg)}>
                <TagsFilterDL
                    hideClear={true}
                    hideLabel={true}
                    readOnly={!canTagDeal}
                    name="Tags"
                    onChange={handleTagsChange}
                    revertOnError={true}
                    eagerLoad
                    initialValue={po.Tags}
                >{working}</TagsFilterDL>
                <FormHelperText>{validationMsg}</FormHelperText>
            </FormControl>
        </Box>
    );
}