import React, { useEffect } from 'react';
import MultiSelectDropDown from 'Components/MultiSelectDropDown';
import { DealsListFilterSettings } from './DealsListFilterSettings';
import { StringUtil } from 'Util/Helpers';
import { useDispatch, useSelector } from "react-redux";
import { fetchActiveTags } from "Policy/State/ActivePolicyTagsSlice";


const resolveInitialValue = (() => {
    const lastFilter = DealsListFilterSettings.getLastUsedFilter();
    if (!StringUtil.isNullOrEmpty(lastFilter)) {
        return lastFilter.Tags;
    }
    return undefined;
})

function TagsFilterDL(props) {
    const dispatch = useDispatch();
    const activeTags = useSelector((state) => state.activePolicyTags.active_tags);
    const activeTagsStatus = useSelector((state) => state.activePolicyTags.status);
    // const error = useSelector((state) => state.activePolicyTags.error) ?? '';

    useEffect(() => {
        if (activeTagsStatus === 'idle') {
            dispatch(fetchActiveTags())
        }
    }, [activeTagsStatus, dispatch])

    const items = activeTags ?? [];

    if (items.length > 0) {
        return (
            <MultiSelectDropDown {...props} items={items} resolveInitialValue={resolveInitialValue}/>
        );
    } else { return null; }
}

export default TagsFilterDL;