import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PolicyApiClient } from 'Policy/PolicyApiClient'

const initialState = {
    tags: [],
    status: 'idle',
    error: null,
    tags_count: 0,
    tags_pages_count: 0,
}

// Thunk functions
export const fetchTags = createAsyncThunk(
    'tags/fetch',
    async (req) => {
        console.info(`[fetchTags] - ${JSON.stringify(req)}`);
        const response = await PolicyApiClient.getPolicyTags(req)
        return response.data
})

export const fetchActiveTags = createAsyncThunk(
    'tags/fetchActive',
    async () => {
        const response = await PolicyApiClient.getActivePolicyTags()
        console.info(`[fetchActiveTags] - ${JSON.stringify(response.data)}`);
        return response.data
})

export const addNewTag = createAsyncThunk(
    'tags/addNew',
    async (newTag) => {
        const response = await PolicyApiClient.createPolicyTag(newTag)
        return response.data
})

export const updateTag = createAsyncThunk(
    'tags/update',
    async (editedTag) => {
        const response = await PolicyApiClient.updatePolicyTag(editedTag);
        return response.data;
})

export const deleteTag = createAsyncThunk(
    'tags/delete',
    async (deletedTag) => {
        const response = await PolicyApiClient.deletePolicyTag(deletedTag);
        return response.data;
})

const TagsSlice = createSlice({
    name: 'tags',
    initialState: initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTags.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchTags.fulfilled, (state, action) => {
                if (state.tags === undefined ||
                    state.tags === null ||
                    state.tags.length === 0) {
                        state.tags = []
                }
                if (action.payload.PolicyTags === undefined ||
                    action.payload.PolicyTags === null ||
                    action.payload.PolicyTags.length === 0) {
                        action.payload.PolicyTags = []
                }
                if (action.payload.PolicyTags.length > 0) {
                    state.tags = action.payload.PolicyTags
                    state.tags_count = action.payload.TotalRecords
                    state.tags_pages_count = action.payload.NumPages
                }
                state.status = 'succeeded'
            })
            .addCase(fetchTags.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
                state.tags = []
            })
            .addCase(fetchActiveTags.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchActiveTags.fulfilled, (state, action) => {
                if (state.tags === undefined ||
                    state.tags === null ||
                    state.tags.length === 0) {
                        state.tags = []
                }
                if (action.payload.PolicyTags === undefined ||
                    action.payload.PolicyTags === null ||
                    action.payload.PolicyTags.length === 0) {
                        action.payload.PolicyTags = []
                }
                if (action.payload.PolicyTags.length > 0) {
                    state.tags = action.payload.PolicyTags
                    state.tags_count = action.payload.PolicyTags.length
                }
                state.status = 'succeeded'
            })
            .addCase(fetchActiveTags.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
                state.tags = []
            })
            .addCase(addNewTag.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addNewTag.fulfilled, (state, action) => {
                state.tags.push(action.payload.PolicyTag)
                state.tags_count += 1
            })
            .addCase(addNewTag.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateTag.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateTag.fulfilled, (state, action) => {
                const index = state.tags.findIndex(tag => tag.Id === action.payload.PolicyTag.Id)
                state.tags[index] = action.payload.PolicyTag
            })
            .addCase(updateTag.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deleteTag.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deleteTag.fulfilled, (state, action) => {
                // state.tags = state.tags.filter(tag => tag.Id !== action.payload.PolicyTag.Id)
                state.tags_count -= 1
            })
            .addCase(deleteTag.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export default TagsSlice.reducer

// Selectors
export const selectAllTags = (state) => state.tags
export const selectTagsStatus = (state) => state.status
export const selectTagsError = (state) => state.error
export const selectTagById = (state, tagId) => state.tags.find(tag => tag.Id === tagId)
export const selectTagCount = state => state.tags_count
export const { reset } = TagsSlice.actions