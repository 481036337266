import PolicyReducer from 'Policy/State/PolicyReducer';
import UserReducer from 'User/State/UserReducer';
import AdminReducer from 'Admin/State/AdminReducer';
import RegistrationReducer from 'User/State/RegistrationReducer';
import AppReducer from 'State/AppReducer';
import TPStaffUsersSlice from 'Admin/State/TPStaffUsersSlice';
import AnnouncementsSlice from 'Admin/State/AnnouncementsSlice';
import ProjectsSlice from 'Admin/State/ProjectsSlice';
import TagsSlice from 'Admin/State/TagsSlice';
import ActivePolicyTagsSlice from 'Policy/State/ActivePolicyTagsSlice';
import { combineReducers } from 'redux';
import ProjectReducer from 'NewHomeProjects/State/ProjectReducer';
import PinDirectoryReducer from 'Admin/State/PinDirListReducer';
import PrivateLendersReducer from 'Admin/State/PrivateLendersReducer';
import MessagesReducer from 'Admin/State/MessagesReducer';

const rootReducer = combineReducers({
    order: PolicyReducer,
    user: UserReducer,
    userRegistration: RegistrationReducer,
    project: ProjectReducer,
    tpStaffUsers: TPStaffUsersSlice,
    announcements: AnnouncementsSlice,
    newHomeProjects: ProjectsSlice,
    policyTags: TagsSlice,
    activePolicyTags: ActivePolicyTagsSlice,
    admin: AdminReducer,
    pinDirectory: PinDirectoryReducer,
    privateLenders: PrivateLendersReducer,
    messages: MessagesReducer,
    app: AppReducer
});

export default rootReducer;
