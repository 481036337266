import { AppSettings } from 'AppSettings';
import ApiResponse from 'Model/ApiResponse';
import * as AppErrors from 'Model/AppErrors';
import { BackgroundCheckContext } from 'Model/BackgroundCheck';
import { ApiHelper } from 'Util/ApiHelper';
import { StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import urlJoin from 'url-join';

class PolicyApiClient {
    logger;

    constructor() {
        this.logger = new Logger(PolicyApiClient.name);
    }

    get uploadFileUrl() {
        return urlJoin(AppSettings.urls.policy, '/policy/UploadFile');
    }

    getErrorMsg = (err) => {
        this.logger.error('Policy API error', err);
        const errMsg = AppErrors.generic;
        const apiResp = new ApiResponse(true);
        apiResp.error = errMsg;
        return apiResp;
    }

    async policyFetch(url, req = {}) {
        try {
            const postUrl = urlJoin(AppSettings.urls.policy, url);
            const authHeaders = await ApiHelper.authHeaders();
            const response = await fetch(postUrl, {
                body: JSON.stringify(req),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                }
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            console.error(ex);
            this.getErrorMsg(ex);
        }
    }

    async policyGetNoCache(url) {
        try {
            const postUrl = urlJoin(AppSettings.urls.policy, url);
            const authHeaders = await ApiHelper.authHeaders();
            const noCacheHeaders = {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                ...authHeaders,
            }
            const response = await fetch(postUrl, {
                method: "GET",
                headers: noCacheHeaders,
            });

            return ApiHelper.deserializeJsonResponse(response);
        }
        catch (ex) {
            console.error(ex);
            this.getErrorMsg(ex);
        }
    }

    // 

    async savePolicy(po, inputs, submitOrder) {
        if (StringUtil.isNullOrEmpty(submitOrder)) {
            submitOrder = false;
        }

        for (const fieldName in inputs) {
            const answer = inputs[fieldName];
            if (!QuestionaryHelper.houseNestedAnswer(fieldName, answer, po)) {
                po[fieldName] = answer;
            }
        }

        const url = `/policy/save?submit=${submitOrder}`;
        const req = {
            PolicyOrder: po,
            SaveAndSubmit: submitOrder
        };
        return await this.policyFetch(url, req);
    }

    async restoreOrder(secureId) {
        const url = '/policy/restore';
        const req = {
            PolicyId: secureId
        };
        return await this.policyFetch(url, req);
    }

    async changeStatus(secureId, newStatus) {
        const url = '/policy/changeStatus';
        const req = {
            SecureId: secureId,
            OrderStatus: newStatus
        };
        return await this.policyFetch(url, req);
    }

    async registerPolicy(secureId) {
        const url = '/policy/RegisterPolicy';
        const req = {
            PolicyId: secureId
        };
        return await this.policyFetch(url, req);
    }

    async loadPolicy(secureId) {
        if (StringUtil.isNullOrEmpty(secureId))
            return;

        const url = `/policy/load?secureId=${secureId}`;
        return await this.policyGetNoCache(url);
    }

    async list(req) {
        if (StringUtil.isNullOrEmpty(req))
            return;

        const url = '/policy/list';
        return await this.policyFetch(url, req);
    }

    async saveNote(secureId, note) {
        const url = '/note/save';
        const req = {
            Note: note,
            PolicyId: secureId
        };
        return await this.policyFetch(url, req);
    }

    async saveNotes(secureId, notes) {
        const url = '/note/batchsave';
        const req = {
            Notes: notes,
            PolicyId: secureId
        };
        return await this.policyFetch(url, req);
    }

    async deleteNote(secureId, note) {
        const url = '/note/delete';
        const req = {
            Note: note,
            PolicyId: secureId
        };
        return await this.policyFetch(url, req);
    }

    async searchNotes(req) {
        const url = '/note/search';
        return await this.policyFetch(url, req);
    }

    async loadNotes(policyId) {
        const url = '/note/loadall';
        const req = {
            PolicyId: policyId
        };
        return await this.policyFetch(url, req);
    }

    async loadReviewRules(policyId) {
        const url = '/note/loadreviewrules';
        const req = {
            SecureId: policyId
        };
        return await this.policyFetch(url, req);
    }

    async retryPinCheckOnPolicies(policyId, properties) {
        const url = '/policy/checkpin';
        const req = {
            PolicyId: policyId,
            Properties: properties,
            Context: BackgroundCheckContext.Retry
        };
        return await this.policyFetch(url, req);
    }

    async retryLawyerCheck(policyId, lsoNumber) {
        const url = '/policy/checklawyer';
        const req = {
            PolicyId: policyId,
            LSONumber: lsoNumber,
            Context: BackgroundCheckContext.Retry
        };
        return await this.policyFetch(url, req);
    }

    async retryNameCheckOnSupplementalDb(policyId, searchText) {
        const url = '/policy/checkName';
        const req = {
            PolicyId: policyId,
            InsuredName: searchText,
            Context: BackgroundCheckContext.Retry
        };
        return await this.policyFetch(url, req);
    }

    async searchPolicyException(searchText, province) {
        const url = '/policy/searchexception';
        const req = {
            SearchCriteria: searchText,
            Province: province
        };
        return await this.policyFetch(url, req);
    }

    async savePolicyExceptions(policyId, exceptions) {
        const url = '/policy/saveexceptions';
        const req = {
            PolicyId: policyId,
            Exceptions: exceptions
        };
        return await this.policyFetch(url, req);
    }
    async deletePolicyException(policyId, exception) {
        const url = '/policy/deleteexception';
        const req = {
            PolicyId: policyId,
            Exception: exception
        };
        return await this.policyFetch(url, req);
    }

    async loadEventLogs(policyId) {
        const url = '/policy/loadEventLogs';
        const req = {
            PolicyId: policyId
        };
        return await this.policyFetch(url, req);
    }

    async loadQuestionary(
        questionaryId,
        questionaryVersion,
        transactionType,
        propertyType,
        isEnrolledInNHP,
        nhpCode,
        propertyAddressProvince,
        addExistingOwnerPolicy,
        existingOwnerHasTIPolicy) {
        const url = '/policy/loadquestionary';
        const req = {
            QuestionaryId: questionaryId,
            QuestionaryVersion: questionaryVersion,
            Property: {
                TransactionType: transactionType,
                PropertyType: propertyType,
                IsEnrolledInNHP: isEnrolledInNHP,
                NHPCode: nhpCode,
                PropertyAddressProvince: propertyAddressProvince,
                AddExistingOwnerPolicy: addExistingOwnerPolicy,
                ExistingOwnerHasTIPolicy: existingOwnerHasTIPolicy
            }
        };
        return await this.policyFetch(url, req);
    }

    async searchPolicyEndorsement(searchText) {
        const url = '/policy/searchendorsement';
        const req = {
            SearchCriteria: searchText
        };
        return await this.policyFetch(url, req);
    }

    async savePolicyEndorsements(policyId, endorsements) {
        const url = '/policy/saveendorsements';
        const req = {
            PolicyId: policyId,
            Endorsements: endorsements
        };
        return await this.policyFetch(url, req);
    }

    async deletePolicyEndorsement(policyId, endorsement) {
        const url = '/policy/deleteendorsement';
        const req = {
            PolicyId: policyId,
            Endorsement: endorsement
        };
        return await this.policyFetch(url, req);
    }

    async searchInsureOver(searchText) {
        const url = '/policy/searchinsureover';
        const req = {
            SearchCriteria: searchText
        };
        return await this.policyFetch(url, req);
    }

    async saveInsureOvers(policyId, insureOvers) {
        const url = '/policy/saveinsureovers';
        const req = {
            PolicyId: policyId,
            InsureOvers: insureOvers
        };
        return await this.policyFetch(url, req);
    }

    async deleteInsureOver(policyId, insureOver) {
        const url = '/policy/deleteinsureover';
        const req = {
            PolicyId: policyId,
            InsureOver: insureOver
        };
        return await this.policyFetch(url, req);
    }

    async searchProject(searchText) {
        const url = '/project/searchproject';
        const req = {
            SearchCriteria: searchText
        };
        return await this.policyFetch(url, req);
    }

    async loadProjectProperty(nhpCode) {
        const url = '/project/loadprojectproperty';
        const req = {
            NHPCode: nhpCode
        };
        return await this.policyFetch(url, req);
    }

    async savePremium(orderSecureId, po, premium) {
        if (StringUtil.isNullOrEmpty(orderSecureId))
            return;

        const url = `/policy/savepremium`;
        const req = {
            PolicyOrder: {
                ...po,
                SecureId: orderSecureId,
                Premium: { ...premium }
            }
        };
        return await this.policyFetch(url, req);
    }

    async getActivePolicyTags() {
        const url = '/policy/GetActivePolicyTags';
        const req = {};
        return await this.policyFetch(url, req);
    }

    async getPolicyTags(req) {
        const url = '/policy/GetPolicyTags';
        return await this.policyFetch(url, req);
    }

    async createPolicyTag(req) {
        const url = '/policy/AddPolicyTag';
        console.info('createPolicyTag req', JSON.stringify(req));
        return await this.policyFetch(url, req);
    }

    async updatePolicyTag(req) {
        const url = '/policy/UpdatePolicyTag';
        console.info('updatePolicyTag req', JSON.stringify(req));
        return await this.policyFetch(url, req);
    }

    async deletePolicyTag(req) {
        const url = '/policy/DeletePolicyTag';
        return await this.policyFetch(url, req);
    }
}

const instance = new PolicyApiClient();

export { instance as PolicyApiClient };
