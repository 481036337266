import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AdminApiClient } from 'Admin/AdminApiClient'

const initialState = {
    new_home_projects: [],
    status: 'idle',
    error: null,
    new_home_projects_count: 0,
    new_home_projects_pages_count: 0,
}

// Thunk functions
export const fetchNewHomeProjects = createAsyncThunk(
    'projects/fetch',
    async (req) => {
        // console.info(`[request] - ${JSON.stringify(req)}`);
        const response = await AdminApiClient.getProjects(req)
        // console.info(`[response] - ${JSON.stringify(response)}`);
        return response.data
    })

const projectsSlice = createSlice({
    name: 'new_home_projects',
    initialState: initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: builder => {
        builder
            .addCase(fetchNewHomeProjects.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchNewHomeProjects.fulfilled, (state, action) => {
                // console.info(`[fetchNewHomeProjects-payload] - ${JSON.stringify(action.payload)}`);
                // console.info(`[fetchNewHomeProjects-state.new_home_projects] - ${JSON.stringify(state.new_home_projects)}`);
                // console.info(`[fetchNewHomeProjects-action.payload.NewHomeProjects] - ${JSON.stringify(action.payload.NewHomeProjects)}`);
                if (state.new_home_projects === undefined ||
                    state.new_home_projects === null ||
                    state.new_home_projects.length === 0) {
                    state.new_home_projects = []
                }
                if (action.payload.NewHomeProjects === undefined ||
                    action.payload.NewHomeProjects === null ||
                    action.payload.NewHomeProjects.length === 0) {
                    action.payload.NewHomeProjects = []
                }
                if (action.payload.NewHomeProjects.length > 0) {
                    state.new_home_projects = action.payload.NewHomeProjects
                    // console.info(`[fetchNewHomeProjects-state.new_home_projects-length] - ${JSON.stringify(state.new_home_projects.length)}`);
                    // console.info(`[fetchNewHomeProjects-state.new_home_projects] - ${JSON.stringify(state.new_home_projects)}`);
                }
                state.new_home_projects_count = action.payload.TotalRecords ?? 0
                // console.info(`[fetchNewHomeProjects-state.new_home_projects_count] - ${state.new_home_projects_count}`);
                state.new_home_projects_pages_count = action.payload.NumPages ?? 1
                // console.info(`[fetchNewHomeProjects-state.new_home_projects_pages_count] - ${state.new_home_projects_pages_count}`);
                state.status = 'succeeded'
            })
            .addCase(fetchNewHomeProjects.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
});

export default projectsSlice.reducer

// Selectors
export const selectNewHomeProjects = (state) => state.new_home_projects
export const selectNewHomeProjectsCount = (state) => state.new_home_projects_count
export const selectNewHomeProjectsStatus = (state) => state.status
export const selectNewHomeProjectsError = (state) => state.error
export const selectNewHomeProjectById = (state, newHomeProjectId) => state.new_home_projects
    .find(nhp => nhp.Id === newHomeProjectId)
export const { reset } = projectsSlice.actions;